export const NAV_LIST = [
  {
    name: '首页',
    icon: 'el-icon-s-home',
    children: [
      {
        name: '详情',
        route: '/analysis'
      }
    ]
  },
  {
    name: '签名',
    icon: 'el-icon-edit-outline',
    children: [
      {
        name: '签名列表',
        route: '/sign'
      }
    ]
  },
  {
    name: '记录',
    icon: 'el-icon-s-order',
    children: [
      {
        name: '记录列表',
        route: '/record'
      }
    ]
  },
  {
    name: '模板',
    icon: 'el-icon-document-copy',
    children: [
      {
        name: '模板列表',
        route: '/module'
      }
    ]
  },
  {
    name: '文档',
    icon: 'el-icon-notebook-1',
    children: [
      {
        name: '接入文档',
        route: '/document'
      }
    ]
  },
  {
    name: '工具',
    icon: 'el-icon-more',
    children: [
      {
        name: '在线发送',
        route: '/online_send'
      }
    ]
  }
  // {
  //     name: '用户',
  //     icon: 'el-icon-s-custom',
  //     children: [{
  //         name: '用户列表',
  //         route: '/user'
  //     }]
  // }
]

export const RECORD_OBJ = {
  created: '待发送',
  sent: '已发送',
  succeed: '发送成功',
  failed: '发送失败'
}

export const RECORD_LIST = [
  {
    name: '待发送',
    value: 'created'
  },
  {
    name: '已发送',
    value: 'sent'
  },
  {
    name: '发送成功',
    value: 'succeed'
  },
  {
    name: '发送失败',
    value: 'failed'
  }
]

export const SIGN_OBJ = {
  created: '待审核',
  available: '可用',
  rejected: '审核未通过'
}

export const MODULE_OBJ = {
  created: '待审核',
  available: '可用',
  rejected: '审核未通过'
}
