
import { useRouter } from 'vue-router'
import { defineComponent, onMounted, ref } from 'vue'
import { NAV_LIST } from '@/config/constEnum'
import axios from 'axios'
import { ElMessage } from 'element-plus'

export default defineComponent({
  name: 'Layout',
  setup() {
    const dialogVisible = ref(false)
    const seeDialogVisible = ref(false)
    const changeDialogVisible = ref(false)
    const navList = NAV_LIST
    const router = useRouter()

    const accessKey = ref('')
    const password = ref('')
    const newPassword = ref('')
    const accessSecret = ref('')
    const confirmNewPassword = ref('')
    const currentIndex = ref(0)

    const exit = () => {
      dialogVisible.value = false
      localStorage.setItem('isLogin', 'false')
      ElMessage.success('退出成功')
      router.push({
        name: 'Login'
      })
    }

    const changePassword = () => {
      if (!password.value) return ElMessage.warning('请填写旧密码')
      if (!newPassword.value) return ElMessage.warning('请填写新密码')
      if (newPassword.value !== confirmNewPassword.value) return ElMessage.warning('新密码和确认新密码不一样！')
      const reqData = {
        old_password: password.value,
        new_password: newPassword.value
      }
      axios
        .post('/-/user/modify/pass', reqData)
        .then(() => {
          changeDialogVisible.value = false
          ElMessage.success('修改密码成功')
          exit()
        })
        .catch(e => {
          if (e.response.data.err_msg === 'old password error') return ElMessage.warning('旧密码错误')
        })
    }

    const getAk = () => {
      axios.get('/-/user/ak/detail').then(res => {
        const data = res.data.msg
        accessKey.value = data.access_key
        accessSecret.value = data.access_secret
      })
    }

    const openIndex = (val: number) => {
      currentIndex.value = val
    }

    onMounted(() => {
      getAk()
    })
    return {
      exit,
      navList,
      password,
      accessKey,
      openIndex,
      newPassword,
      accessSecret,
      currentIndex,
      dialogVisible,
      changePassword,
      seeDialogVisible,
      confirmNewPassword,
      changeDialogVisible
    }
  }
})
