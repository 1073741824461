import type { App } from 'vue'
import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router'
import IndexPage from '@/views/home/index.vue'
import Login from '@/views/login/index.vue'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'index',
    component: IndexPage,
    children: [
      {
        path: '/homePage',
        name: 'Home',
        component: () => {
          import('@/views/sign/index.vue')
        }
      },
      {
        path: '/analysis',
        name: 'analysis',
        component: () => import('@/views/home/analysis.vue')
      },
      {
        path: '/record',
        name: 'record',
        component: () => import('@/views/record/index.vue')
      },
      {
        path: '/sign',
        name: 'sign',
        component: () => import('@/views/sign/index.vue')
      },
      {
        path: '/module',
        name: 'module',
        component: () => import('@/views/module/index.vue')
      },
      {
        path: '/user',
        name: 'user',
        component: () => import('@/views/user/index.vue')
      },
      {
        path: '/document',
        name: 'document',
        component: () => import('@/views/document/index.vue')
      },
      {
        path: '/online_send',
        name: 'online_send',
        component: () => import('@/views/online_send/index.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach(to => {
  const isLogin = localStorage.getItem('isLogin') === 'true'
  if (to.name !== 'Login' && !isLogin) {
    router.push({
      name: 'Login'
    })
  }
})

// 配置路由信息
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function setupRouter(app: App<Element>) {
  app.use(router)
}
export default router
